<template>
  <div class="page">
    <NavBar />
    <div class="container">
      <h1 class="about__title">ERREUR 404</h1>
      <p>la page demandée n'existe pas!</p>
      <p>Pas d'inquiétude, il reste d'autres choses à voir sur mon site.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
